<template>
  <b-modal
    :id="id"
    size="md"
    title="Thu hồi giấy phép/ giấy xác nhận"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleRevokeLicense"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group
        class="mt-1"
        label-for="classfication"
      >
        <label for="user">Số quyết định thu hồi <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customCode"
        >
          <b-form-input
            id="InputHelp"
            v-model="dataRevoke.numberRevoke"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>

    <b-form-group
      class="mt-1"
      :label="'Căn cứ'"
      label-for="year"
    >
      <b-form-input v-model="dataRevoke.basedOn" />
    </b-form-group>

    <b-form-group
      class="mt-1"
      :label="'Theo đề nghị của'"
      label-for="year"
    >
      <b-form-input v-model="dataRevoke.suggestBy" />
    </b-form-group>

    <b-form-group class="modal-accepte">
      <div class="modal-accept">
        <b-form-group>
          <label>Chọn file</label>
          <div class="d-flex">
            <b-form-input
              v-model="dataRevoke.nameFile"
              :disabled="true"
            >
              {{ dataRevoke.nameFile }}
            </b-form-input>
            <input
              id="file"
              ref="file-second"
              type="file"
              class="input-file"
              @change="importFileExcel"
            />
            <b-button
              variant="outline-primary"
              class="btn-button-file ml-1"
              @click="inputFile"
            >
              <div
                class="d-flex"
              >
                <feather-icon
                  icon="UploadIcon"
                  for="file"
                  class="input_label text-primary"
                />
                <label
                  class="text-primary ml-50"
                >Tải lên</label>
              </div>
            </b-button>
          </div>
        </b-form-group>
      </div>
    </b-form-group>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BButton,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataRevoke: {
        numberRevoke: '',
        basedOn: '',
        suggestBy: '',
        nameFile: '',
        modelFormData: null,
      },
      customCode: {
        required: 'Số quyết định thu hồi là bắt buộc',
      },
    }
  },
  methods: {
    handleRevokeLicense(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          const formData = new FormData()
          if (this.dataRevoke.modelFormData === null) {
            formData.append('NumberRevoke', this.dataRevoke.numberRevoke)
            formData.append('BasedOn', this.dataRevoke.basedOn)
            formData.append('SuggestBy', this.dataRevoke.suggestBy)
            formData.append('NameFile', this.dataRevoke.nameFile)
          } else {
            formData.append('IsSecure', this.dataRevoke.modelFormData.isSecure)
            formData.append('formFile', this.dataRevoke.modelFormData.files)
            formData.append('NumberRevoke', this.dataRevoke.numberRevoke)
            formData.append('BasedOn', this.dataRevoke.basedOn)
            formData.append('SuggestBy', this.dataRevoke.suggestBy)
            formData.append('NameFile', this.dataRevoke.nameFile)
          }
          this.$emit('handleRevokeLicense', formData)
        }
      })
    },

    inputFile() {
      this.$refs['file-second'].click()
    },
    importFileExcel(e) {
      this.dataRevoke.nameFile = this.$refs['file-second'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataRevoke.modelFormData = {
        files: file,
        isSecure: false,
      }
    },

    resetState() {
      this.dataRevoke = {
        numberRevoke: '',
        basedOn: '',
        suggestBy: '',
      }
    },
  },
}
</script>

<style lang="scss">
.modal-accepte {
  .btn-button-file {
    width: 40%;
    height: 38px;
  }
  .input-file {
    display: none;
  }
}
</style>
