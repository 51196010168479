<template>
  <div>
    <div class="page-container pb-0 mb-2">
      <div id="filter-custom">
        <h3
          class="header"
        >
          Bộ lọc
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Loại giấy phép <span class="required"></span></label>
              <v-select
                :reduce="label => label.workerLicenseType"
                label="label"
                :options="dataLicense || []"
                :placeholder="'Loại giấy phép'"
                @input="licenseFilter"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ $t(option.workerLicenseTypeString) }}
                </template>
                <template #selected-option-container="{ option }">
                  <div class="vs__selected">
                    {{ $t(option.workerLicenseTypeString) }}
                  </div>
                </template>
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Trạng thái<span class="required"></span></label>
              <v-select
                :reduce="label => label.status"
                label="label"
                :options="dataStatusFilter || []"
                :placeholder="'Trạng thái'"
                @input="filterStatus"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ $t(option.statusString) }}
                </template>
                <template #selected-option-container="{ option }">
                  <div class="vs__selected">
                    {{ $t(option.statusString) }}
                  </div>
                </template>
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Doanh nghiệp<span class="required"></span></label>
              <v-select
                :reduce="label => label.id"
                label="name"
                :options="dataComboboxBusiness || []"
                :placeholder="'Doanh nghiệp'"
                @input="filterBusiness"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ option.name }}
                </template>
                <template #selected-option-container="{ option }">
                  <div class="vs__selected">
                    {{ option.name }}
                  </div>
                </template>
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

      </div>
    </div>
    <div class="page-container-table">

      <div class="d-flex justify-content-between flex-wrap">
        <div
          id="btn-all-header"
          class="d-flex"
        >
          <!-- -------------Delete------------- -->
          <button-component
            v-b-tooltip.hover.top="'Tạo sổ theo dõi cấp phép người lao động'"
            class="ml-2 button-icon"
            content-btn=""
            variant="primary"
            icon-btn="DownloadIcon"
            @click="downloadFile"
          />
        </div>
        <div class="header-action">
          <search
            class="search-input mr-1 ml-1"
            @change="search"
          />
        </div>
      </div>
      <!--Phần Bảng -->
      <template>
        <vue-good-table
          ref="user-table"
          :columns="columns"
          :rows="dataTable || []"
          :sort-options="{
            enabled: false,
          }"
          :select-options="{ enabled: false, selectOnCheckboxOnly: true }"
          style-class="vgt-table"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- tên -->
            <span v-if="props.column.field == 'name'">
              {{ props.row.name }}
            </span>
            <!-- số giáy phếp -->
            <span v-else-if="props.column.field == 'number'">
              {{ props.row.number }}
            </span>
            <!-- loại -->
            <span v-else-if="props.column.field === 'workerLicenseTypeString'">
              <b-badge
                pill
                :variant="statusVariant(props.row.workerLicenseType)"
                class="border-status"
              >
                <span>{{ props.row.workerLicenseType === 'License' ? 'Giấy cấp phép' : 'Giấy xác nhận' }}</span>

              </b-badge>
            </span>
            <!-- doanh nghiệp -->
            <span v-else-if="props.column.field == 'bussiness'">
              {{ props.row.bussiness }}
            </span>
            <!-- ngày bắt đầu -->
            <span v-else-if="props.column.field == 'dateStart'">
              {{ props.row.dateStart | formatDateToDDMM }}
            </span>
            <!-- ngày kết thúc -->
            <span v-else-if="props.column.field == 'dateEnd'">
              {{ props.row.dateEnd | formatDateToDDMM }}
            </span>
            <!-- ngày hẹn trả -->
            <span v-else-if="props.column.field == 'dateProcess'">
              <span
                v-if="props.row.status === 'PendingRenew'"
              >
                {{ props.row.dateReceiveRenew | formatDateToDDMM }}
              </span>
              <span
                v-if="props.row.status === 'PendingConfirm'"
              >
                {{ props.row.dateReceiveConfirm | formatDateToDDMM }}
              </span>
              <span
                v-if="props.row.status === 'PendingLicensing'"
              >
                {{ props.row.dateReceiveLicense | formatDateToDDMM }}
              </span>
            </span>
            <!-- statusString : Trạng thái  -->
            <span
              v-else-if="props.column.field == 'statusString' "
            >
              <b-badge
                pill
                :variant="colorStatus(props.row.status)"
                class="border-status"
              >
                <span> {{ props.row.statusString }}</span>
              </b-badge>
            </span>
            <!-- Chức năng -->
            <span v-else-if="props.column.field == 'cn'">
              <span v-if="props.row.status === 'Revoke'">
                <feather-icon
                  v-if="props.row.fileNameRevoke"
                  v-b-tooltip.hover.top="'Tải xuống'"
                  icon="DownloadIcon"
                  size="18"
                  :class="['Revoke'].includes(props.row.status) && ['Confirm'].includes(props.row.workerLicenseType) ? 'mr-1' : ''"
                  @click="downloadRevoke(props.row.id, props.row.fileNameRevoke)"
                />

                <feather-icon
                  v-if="!props.row.fileNameRevoke"
                  v-b-tooltip.hover.top="'Không có file tải xuống'"
                  icon="DownloadIcon"
                  size="18"
                  :class="['Revoke'].includes(props.row.status) && ['Confirm'].includes(props.row.workerLicenseType) ? 'mr-1' : ''"
                  class="text-disable"
                />

              </span>
              <span
                v-if="(!['Renew', 'Revoke', 'PendingRenew'].includes(props.row.status)
                  && props.row.workerLicenseType === 'Confirm') || (props.row.status !== 'RejectLicense'
                  && props.row.workerLicenseType === 'License')"
              >
                <b-dropdown
                  v-if="!['RejectConfirm', 'RejectRenew', 'RejectExtend'].includes(props.row.status)"
                  variant="link"
                  boundary="viewport"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      v-b-tooltip.hover.top="'Xem đơn'"
                      icon="EyeIcon"
                      size="18"
                      class="text-body"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="props.row.status === 'PendingConfirm'
                      && props.row.workerLicenseType === 'Confirm'"
                    @click="openModalSeePdf(props.row.id, props.row.status)"
                  >
                    <span>{{ 'Đơn đề nghị xác nhận lao động nước ngoài' }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.workerLicenseType === 'License'
                      && (props.row.status === 'PendingLicensing'
                        || props.row.status === 'PendingRenew'
                        || props.row.status === 'PendingExtend')"
                    @click="openModalSeePdf(props.row.id, props.row.status)"
                  >
                    <span>{{ 'Đơn đề nghị gia hạn/ cấp lại lao động nước ngoài' }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.status === 'Confirm'
                      && props.row.workerLicenseType === 'Confirm'"
                    @click="openModalSeePdf(props.row.id, props.row.status)"
                  >
                    <span>{{ 'Giấy xác nhận lao động nước ngoài' }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.status === 'RejectConfirm'
                      && props.row.workerLicenseType === 'Confirm'"
                    @click="openModalSeePdf(props.row.id, props.row.status)"
                  >
                    <span>{{ 'Giấy từ chối xác nhận lao động nước ngoài' }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="(props.row.status === 'Licensing'
                      || props.row.status === 'Extend'
                      || props.row.status === 'Renew')
                      && props.row.workerLicenseType === 'License'"
                    @click="openModalSeePdf(props.row.id, props.row.status)"
                  >
                    <span>{{ 'Giấy phép lao động nước ngoài' }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.status === 'Revoke'
                      && props.row.workerLicenseType === 'License'"
                    @click="openModalSeePdf(props.row.id, props.row.status)"
                  >
                    <span>{{ 'Giấy thu hồi giấy phép' }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
              <feather-icon
                v-b-tooltip.hover.top="'Xem file'"
                icon="FileTextIcon"
                size="18"
                @click="openModalDownFile(props.row.id)"
              />
              <span
                v-if="!['Revoke', 'RejectLicense', 'RejectConfirm', 'RejectRenew', 'RejectExtend'].includes(props.row.status)"
              >
                <b-dropdown
                  variant="link"
                  boundary="viewport"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="text-body"
                    />
                  </template>

                  <span v-if="props.row.status === 'PendingConfirm'">
                    <b-dropdown-item @click="openAcceptConfirm(props.row.id)">
                      <feather-icon
                        icon="CheckCircleIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Duyệt xác nhận' }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="openRefuseConfirm(props.row.id)">
                      <feather-icon
                        icon="XCircleIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Từ chối xác nhận' }}</span>
                    </b-dropdown-item>
                  </span>

                  <span v-if="props.row.status === 'PendingLicensing'">
                    <b-dropdown-item @click="openAcceptLicense(props.row.id)">
                      <feather-icon
                        icon="CheckCircleIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Duyệt cấp phép' }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="openRefuseLicense(props.row.id)">
                      <feather-icon
                        icon="XCircleIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Từ chối cấp phép' }}</span>
                    </b-dropdown-item>
                  </span>

                  <span v-if="props.row.status === 'PendingRenew'">
                    <b-dropdown-item
                      @click="openReNewLicense(props.row.id)"
                    >
                      <feather-icon
                        icon="CheckCircleIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Duyệt cấp lại' }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="openRejectReNewLicense(props.row.id)"
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Từ chối duyệt cấp lại' }}</span>
                    </b-dropdown-item>
                  </span>

                  <span v-if="props.row.status === 'PendingExtend'">
                    <b-dropdown-item
                      @click="openExtendLicense(props.row.id)"
                    >
                      <feather-icon
                        icon="CheckCircleIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Duyệt gia hạn' }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="openRejectExtendLicense(props.row.id)"
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        class="mr-50"
                      />
                      <span>{{ 'Từ chối duyệt gia hạn' }}</span>
                    </b-dropdown-item>
                  </span>

                  <b-dropdown-item
                    v-if="props.row.status === 'Confirm'
                      || props.row.status === 'Licensing'
                      || props.row.status === 'Extend'
                      || props.row.status === 'Renew'"
                    @click="openRevokeLicense(props.row.id)"
                  >
                    <feather-icon
                      icon="CornerDownLeftIcon"
                      class="mr-50"
                    />
                    <span>{{ 'Thu hồi' }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="['PendingLicensing', 'PendingConfirm', 'PendingRenew'].includes(props.row.status)"
                    @click="openModalFileSend(props.row.id, props.row.status)"
                  >
                    <feather-icon
                      icon="FilePlusIcon"
                      class="mr-50"
                    />
                    <span>{{ 'Xem hồ sơ cần nộp' }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>

            </span>
          </template>
        </vue-good-table>
        <my-pagination
          :totalItems="totalRecord"
          :currentPage="urlQuery.pageNumber"
          @pageClick="handlePageClick"
        />
      </template>

      <!-- modal downfile -->
      <modal-down-file
        :id="modalDownFile"
        :dataDownloadFile="dataDownloadFile"
        :totalRecordDownloadFile="totalRecordDownloadFile"
        :queryParams="queryParams"
        @searchFile="searchFile"
        @handlePageClickPage="handlePageClickPage"
      />

      <!-- modal xem file pdf -->
      <modal-see-pdf
        :id="modalSeePdf"
        :pdfsrc="pdfsrc"
        @downloadFile="downloadFilePdf"
      />

      <!-- modal duyệt xác nhận -->
      <modal-accept-confirm
        :id="modalAcceptConfirm"
        @handleAcceptConfirm="handleAcceptConfirm"
      />

      <!-- modal thu hồi giấy phép-->
      <modal-revoke-license
        :id="modalRevokeLicense"
        @handleRevokeLicense="handleRevokeLicense"
      />

      <!-- modal từ chối duyệt xác nhận -->
      <modal-refuse-confirm
        :id="modalRefuseConfirm"
        @handleRefeseConfirm="handleRefeseConfirm"
      />

      <!-- modal duyệt cấp phép -->
      <modal-accept-license
        :id="modalAcceptLicense"
        @handleAcceptLicense="handleAcceptLicense"
      />

      <!-- modal từ chối duyệt cấp phép  -->
      <modal-refuse-license
        :id="modalRefuseLicense"
        @handleRefuseLicense="handleRefuseLicense"
      />

      <!-- modal cấp lại giấy phép  -->
      <modal-renew-license
        :id="modalRenewLicense"
        @handleRenewLicense="handleRenewLicense"
      />

      <!-- modal gia hạn giấy phép-->
      <confirm-modal
        :id="confirmModalId"
        :title="'Bạn chắc chắn gia hạn?'"
        :content="modalContent"
        @accept="extendLicence"
      />

      <!-- modal từ chối gia hạn giấy phép-->
      <confirm-modal
        :id="confirmModalExtendId"
        :title="'Bạn chắc chắn từ chối gia hạn?'"
        :content="'Bạn có muốn từ chối gia hạn'"
        @accept="extendLicenceExtend"
      />

      <!-- modal từ chối cấp lại giấy phép-->
      <confirm-modal
        :id="confirmModalRenewId"
        :title="'Bạn chắc chắn từ chối cấp lại'"
        :content="'Bạn có muốn từ chối cấp lại'"
        @accept="extendLicenceRenew"
      />

      <!-- modal xem hồ sơ cần nộp-->
      <modal-send-file
        :id="modalSendFile"
        :dataTableModal="dataTableModal"
      />
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem, VBTooltip,
} from 'bootstrap-vue'

import VSelect from 'vue-select'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import DataFilter from '../../worker-permit/constants/DataFilter'
import Search from '@/components/search/Search.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import ModalDownFile from './components/ModalDownFile.vue'
import ModalSeePdf from './components/ModalSeePdf.vue'
import ModalAcceptConfirm from './components/ModalAcceptConfirm.vue'
import ModalRevokeLicense from './components/ModalRevoke.vue'
import ModalRefuseConfirm from './components/ModalRefuseConfirm.vue'
import ModalAcceptLicense from './components/ModalAcceptLicense.vue'
import ModalRefuseLicense from './components/ModalRefuseLicense.vue'
import ModalRenewLicense from './components/ModalRenewLicense.vue'
import ModalSendFile from './components/ModalSendFile.vue'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VSelect,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    ShowFile,
    ModalDownFile,
    ModalSeePdf,
    ModalAcceptConfirm,
    ModalRevokeLicense,
    ModalRefuseConfirm,
    ModalAcceptLicense,
    ModalRefuseLicense,
    ModalRenewLicense,
    ModalSendFile,
    VBTooltip,
    ButtonComponent,
    Search,
  },
  filters: {
    formatDateToDDMM,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      modalContent: '',
      confirmModalId: 'comfirm-modal',
      modalRenewLicense: 'confirm-renew-license',
      modalRefuseLicense: 'confirm-refuse-license',
      modalAcceptLicense: 'confirm-accept-license',
      modalRefuseConfirm: 'confirm-refuse',
      modalRevokeLicense: 'confirm-revoke',
      modalAcceptConfirm: 'confirm-accept-confirm',
      confirmModalExtendId: 'comfirm-modal-extend',
      confirmModalRenewId: 'comfirm-modal-renew',
      modalSeePdf: 'comfirm-pdf',
      modalDownFile: 'comfirm-download',
      modalSendFile: 'confirm-modal-send-file',
      dataStatusFilter: DataFilter.dataStatus,
      dataLicense: DataFilter.dataWorkerLicenseType,
      columns: [
        {
          label: 'SỐ GIẤY PHÉP',
          field: 'numberLicense',
          width: '200px',
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
        {
          label: 'HỌ TÊN',
          field: 'name',

        },
        {
          label: 'LOẠI GIẤY',
          field: 'workerLicenseTypeString',
        },
        {
          label: 'DOANH NGHIỆP',
          field: 'bussiness',
        },
        {
          label: 'NGÀY BẮT ĐẦU',
          field: 'dateStart',
        },
        {
          label: 'NGÀY KẾT THÚC',
          field: 'dateEnd',
        },
        {
          label: 'NGÀY HẸN TRẢ',
          field: 'dateProcess',
        },
        {
          label: 'TRẠNG THÁI',
          field: 'statusString',
        },
        {
          label: 'CHỨC NĂNG',
          width: '200px',
          tdClass: 'actiontd',
          thClass: 'actionth',

          field: 'cn',
        },
      ],
      // ==phân trang
      totalRecord: 0,
      // ==Dữ liệu fetch list
      urlQuery: {
        bussinessId: null,
        workerLicenseType: null,
        status: null,
        key: null,
        pageSize: 10,
        pageNumber: 1,
      },
      dataTable: [],
      dataComboboxBusiness: [],
      showBtnMultiDelete: false,
      dataDownloadFile: [],
      totalRecordDownloadFile: 0,
      queryParams: {
        workerLicenseId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      pdfsrc: null,
      typeStatus: null,
      urlApi: null,
      workerLicenseAcceptId: null,
      statusFileName: null,
      nameFilePdf: null,
      urlQueryModal: {
        workerLicenseId: '',
        type: '',
      },
      dataTableModal: [],
      typeStatusModal: null,
    }
  },
  // ======================WATCH=============================
  // ==========================COMPUTED=======================
  computed: {
    colorStatus() {
      const statusColor = {
        None: 'info',
        NonLiscening: 'secondary',
        PendingConfirm: 'warning',
        Confirm: 'success',
        RejectConfirm: 'danger',
        Licensing: 'primary',
        RejectLicense: 'danger',
        PendingRenew: 'warning',
        PendingExtend: 'warning',
        Revoke: 'danger',
        PendingLicensing: 'warning',
        Extend: 'success',
        Renew: 'primary',
        RejectExtend: 'danger',
        RejectRenew: 'danger',
      }
      return status => statusColor[status]
    },

    statusVariant() {
      // eslint-disable-next-line default-case
      const typeColor = {
        License: 'light-primary',
        Confirm: 'light-success',
      }
      return color => typeColor[color]
    },
  },
  // ===========================CREATED====================
  created() {
    this.fetchDataWorkerLicense(this.urlQuery)
    this.fetchComboboxBusiness()
  },
  // =============================METHOD======================
  methods: {

    // Mở modal Thêm hồ sơ cần nộp
    async openModalFileSend(id, status) {
      this.urlQueryModal.workerLicenseId = id
      this.typeStatusModal = status
      await this.fetchListFileSend(this.urlQueryModal)
      this.$bvModal.show(this.modalSendFile)
    },

    // Lấy danh sách hồ sơ cần nộp
    async fetchListFileSend(urlQuery) {
      switch (this.typeStatusModal) {
        case 'PendingLicensing':
          urlQuery.type = 'CM'
          break
        case 'PendingConfirm':
          urlQuery.type = 'XN'
          break
        case 'PendingRenew':
          urlQuery.type = 'CL'
          break
        default:
          break
      }
      const data = await axiosApiInstance.get(ConstantsApi.LIST_FILE_REQUIRES, {
        params: urlQuery,
      })
      this.dataTableModal = data?.data
    },

    // Tải file thu hồi
    downloadRevoke(id, fileName) {
      axiosApiInstance({
        url: ConstantsApi.DOWN_FILE_REVOKE, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { id },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // Mở modal duyệt xác nhận
    openAcceptConfirm(id) {
      this.workerLicenseAcceptId = id
      this.$bvModal.show(this.modalAcceptConfirm)
    },

    // Mở modal duyệt xác nhận
    openRevokeLicense(id) {
      this.workerLicenseAcceptId = id
      this.$bvModal.show(this.modalRevokeLicense)
    },

    // Mở modal từ chối duyệt xác nhận
    openRefuseConfirm(id) {
      this.workerLicenseAcceptId = id
      this.$bvModal.show(this.modalRefuseConfirm)
    },

    // Mở modal duyệt cấp phép
    openAcceptLicense(id) {
      this.workerLicenseAcceptId = id
      this.$bvModal.show(this.modalAcceptLicense)
    },

    // Mở modal từ chối duyệt cấp phép
    openRefuseLicense(id) {
      this.workerLicenseAcceptId = id
      this.$bvModal.show(this.modalRefuseLicense)
    },

    // Mở modal Cấp lại giấy phép
    openReNewLicense(id) {
      this.workerLicenseAcceptId = id
      this.$bvModal.show(this.modalRenewLicense)
    },

    // Duyệt cấp lại
    async openExtendLicense(id) {
      this.workerLicenseAcceptId = id
      this.modalContent = this.$t('Bạn có muốn gia hạn cấp phép?')
      this.$bvModal.show(this.confirmModalId)
    },

    // Mở modal từ chối duyệt cấp lại
    openRejectReNewLicense(id) {
      this.workerLicenseAcceptId = id
      this.$bvModal.show(this.confirmModalRenewId)
    },

    // Mở modal từ chối duyệt cấp lại
    openRejectExtendLicense(id) {
      this.workerLicenseAcceptId = id
      this.$bvModal.show(this.confirmModalExtendId)
    },

    // Xác nhận duyệt giấy
    async handleAcceptConfirm(val) {
      const model = {
        ...val,
        id: this.workerLicenseAcceptId,
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.ACCEPT_CONFIRM, model).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Duyệt thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalAcceptConfirm)
        this.fetchDataWorkerLicense(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Duyệt không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Thu hồi giấy phép
    async handleRevokeLicense(val) {
      val.append('Id', this.workerLicenseAcceptId)
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REVOKE_LICENSE, val).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Thu hồi thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalRevokeLicense)
        this.fetchDataWorkerLicense(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Thu hồi không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Từ chối duyệt giấy xác nhận
    async handleRefeseConfirm(val) {
      const model = {
        ...val,
        id: this.workerLicenseAcceptId,
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REFUSE_CONFIRM, model).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Từ chối thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalRefuseConfirm)
        this.fetchDataWorkerLicense(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Từ chối không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Xác nhận duyệt cấp phép
    async handleAcceptLicense(val) {
      const model = {
        ...val,
        id: this.workerLicenseAcceptId,
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.ACCEPT_LICENSE, model).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Duyệt thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalAcceptLicense)
        this.fetchDataWorkerLicense(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Duyệt không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Từ chối duyệt cấp phép
    async handleRefuseLicense(val) {
      const model = {
        ...val,
        id: this.workerLicenseAcceptId,
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REFUSE_LICENSE, model).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Từ chối thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalRefuseLicense)
        this.fetchDataWorkerLicense(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Từ chối không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Duyệt cấp lại giấy phép
    async handleRenewLicense(val) {
      const model = {
        ...val,
        id: this.workerLicenseAcceptId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.RENEW_LICENSE, model).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Cấp lại thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalRenewLicense)
        this.fetchDataWorkerLicense(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Cấp lại không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async extendLicenceExtend() {
      const model = {
        id: this.workerLicenseAcceptId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REJECT_EXTEND_LICENSE, model).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Gia hạn thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.confirmModalId)
        this.fetchDataWorkerLicense(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Gia hạn không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async extendLicenceRenew() {
      const model = {
        id: this.workerLicenseAcceptId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REJECT_RENEW_LICENSE, model).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Gia hạn thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.confirmModalId)
        this.fetchDataWorkerLicense(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Gia hạn không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async extendLicence() {
      const model = {
        id: this.workerLicenseAcceptId,
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.EXTEND_LICENSE, model).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Gia hạn thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.confirmModalId)
        this.fetchDataWorkerLicense(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Gia hạn không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Mở modal xem file pdf
    openModalSeePdf(id, status) {
      this.statusFileName = status
      this.workerLicenseAcceptId = id
      switch (status) {
        case 'PendingConfirm':
          this.urlApi = '/WorkerLicense/file-require-confirm'
          break
        case 'PendingLicensing':
        case 'PendingRenew':
        case 'PendingExtend':
          this.urlApi = '/WorkerLicense/file-require-license'
          break
        case 'Confirm':
          this.urlApi = '/WorkerLicense/file-confirm'
          break
        case 'RejectConfirm':
          this.urlApi = '/WorkerLicense/file-refuse-confirm'
          break
        case 'Revoke':
          this.urlApi = '/WorkerLicense/file-revoke-license'
          break
        case 'Licensing':
        case 'Extend':
        case 'Renew':
          this.urlApi = '/WorkerLicense/file-license'
          break
        default:
          break
      }
      switch (status) {
        case 'PendingLicensing':
          this.typeStatus = 0
          break
        case 'PendingRenew':
          this.typeStatus = 1
          break
        case 'PendingExtend':
          this.typeStatus = 2
          break
        case 'PendingConfirm':
        case 'Renew':
        case 'Extend':
        case 'Licensing':
        case 'Revoke':
        case 'RejectConfirm':
        case 'Confirm':
          this.typeStatus = null
          break
        default:
          break
      }
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { workerLicenseId: this.workerLicenseAcceptId, type: this.typeStatus },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Tải file pdf
    downloadFilePdf() {
      switch (this.statusFileName) {
        case 'PendingConfirm':
          this.nameFilePdf = 'DonDeNghiXacNhanNguoiLaoDong.pdf'
          break
        case 'PendingLicensing':
        case 'PendingRenew':
        case 'PendingExtend':
          this.nameFilePdf = 'DonDeNghiCapPhepLaoDong.pdf'
          break
        case 'Confirm':
          this.nameFilePdf = 'GiayXacNhanNguoiLaoDong.pdf'
          break
        case 'RejectConfirm':
          this.nameFilePdf = 'GiayTuChoiXacNhanNguoiLaoDong.pdf'
          break
        case 'Revoke':
          this.nameFilePdf = 'GiayThuHoiGiayPhep.pdf'
          break
        case 'Licensing':
        case 'Extend':
        case 'Renew':
          this.nameFilePdf = 'GiayPhepLaoDong.pdf'
          break
        default:
          break
      }
      switch (this.statusFileName) {
        case 'PendingLicensing':
          this.typeStatus = 0
          break
        case 'PendingRenew':
          this.typeStatus = 1
          break
        case 'PendingExtend':
          this.typeStatus = 2
          break
        case 'PendingConfirm':
        case 'Renew':
        case 'Extend':
        case 'Licensing':
        case 'Revoke':
        case 'RejectConfirm':
        case 'Confirm':
          this.typeStatus = null
          break
        default:
          break
      }
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { workerLicenseId: this.workerLicenseAcceptId, type: this.typeStatus },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.nameFilePdf) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // Lấy danh sách đề nghị cấp phép người lao động
    async fetchDataWorkerLicense(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_WORKERS_LICENSE, {
        params: urlQuery,
      })
      this.dataTable = data.data.pageLists
      this.totalRecord = data.data.totalRecord
      this.$hideLoading()
    },

    // Danh sách combobox doanh nghiệp
    async fetchComboboxBusiness() {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_COMBOBOX_BUSINESS)
      this.dataComboboxBusiness = data?.data
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchDataWorkerLicense(this.urlQuery)
    },

    // lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchDataWorkerLicense(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchDataWorkerLicense(this.urlQuery)
      }
    },

    // lấy danh sách khi lọc theo trạng thái
    filterStatus(val) {
      if (val !== null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.status = val
        this.fetchDataWorkerLicense(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.status = ''
        this.fetchDataWorkerLicense(this.urlQuery)
      }
    },

    // Lấy dan hsachs lọc theo giấy phép
    licenseFilter(val) {
      if (val !== null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.workerLicenseType = val
        this.fetchDataWorkerLicense(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.workerLicenseType = ''
        this.fetchDataWorkerLicense(this.urlQuery)
      }
    },

    // Lấy dan hsachs lọc theo giấy phép
    filterBusiness(val) {
      if (val !== null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = val
        this.fetchDataWorkerLicense(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = ''
        this.fetchDataWorkerLicense(this.urlQuery)
      }
    },

    // Danh sách file để thêm
    async fetchDownloadFile(queryParams) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_WORKER_DOWN_FILE, {
        params: queryParams,
      })
      this.dataDownloadFile = data.data.pageLists
      this.totalRecordDownloadFile = data.data.totalRecord
      this.$hideLoading()
    },

    // Mở modal danh sách file để download
    async openModalDownFile(id) {
      this.queryParams.workerLicenseId = id
      await this.fetchDownloadFile(this.queryParams)
      this.$bvModal.show(this.modalDownFile)
    },

    // lấy danh sách khi tìm kiếm file
    searchFile(val) {
      if (val != null) {
        this.queryParams.pageNumber = 1
        this.queryParams.key = val
        this.fetchDownloadFile(this.queryParams)
      } else {
        this.queryParams.pageNumber = 1
        this.queryParams.key = ''
        this.fetchDownloadFile(this.queryParams)
      }
    },

    // Xử lý khi ấn nút phân trang thêm file
    handlePageClickPage(pageNumber, pageSize) {
      this.queryParams.pageNumber = pageNumber
      this.queryParams.pageSize = pageSize
      this.fetchDownloadFile(this.queryParams)
    },

    // Tạo sooe theo dõi cấp phép người lao động
    downloadFile() {
      axiosApiInstance({
        url: '/WorkerLicense/file-tracking-worker', // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'SoTheoDoiCapPhepLaoDong.docx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>

<style lang="scss">
#filter-custom{
  .header{
    font-size: 18px;
    color: #181F28;
  }
}
.actiontd{
  text-align: center;
}
.actionth{
  text-align: center;
}
.bg-color{
  color : #fff;
  text-align: center;
}
.inline{
  display: inline-block;
}
.custtom-header{
    padding-right: 20px;
}

.border-status {
    border-radius: 4px;
  }
.type-blue{
     background-color: #227FF41F;
    border-radius: 4px;
  padding:1px 9px;
  color: #227FF4;
}
.type-green{
     background-color: #28C76F1F;
    border-radius: 4px;
  padding:1px 9px;
  color: #28C76F;
}
.text-disable {
  color: #797E84;
}

</style>
