export default {
  LIST_WORKERS_LICENSE: 'WorkerLicense/workerLicenses',
  LIST_COMBOBOX_BUSINESS: 'Bussiness/get-combobox-bussiness',
  LIST_WORKER_DOWN_FILE: 'WorkerLicense/list-file-in-worker-license',
  ACCEPT_CONFIRM: 'WorkerLicense/accept-confirm',
  REFUSE_CONFIRM: 'WorkerLicense/refuse-confirm',
  REVOKE_LICENSE: 'WorkerLicense/revoke-license',
  ACCEPT_LICENSE: 'WorkerLicense/accept-license',
  REFUSE_LICENSE: 'WorkerLicense/refuse-license',
  RENEW_LICENSE: 'WorkerLicense/renew-license',
  EXTEND_LICENSE: 'WorkerLicense/extend-license',
  REJECT_EXTEND_LICENSE: 'WorkerLicense/reject-extend-license',
  REJECT_RENEW_LICENSE: 'WorkerLicense/reject-rewnew-license',
  DOWN_FILE_REVOKE: 'WorkerLicense/download-revoke-file',
  LIST_FILE_REQUIRES: 'WorkerLicense/list-file-requires',
  UPDATE_FILE_REQUIRES: 'WorkerLicense/update-file-require',
  DOWNLOAD_FILE_REQUIRES: 'WorkerLicense/download-file-require',
}
