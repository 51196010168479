<template>
  <div>
    <b-modal
      :id="id"
      size="lg"
      title="Xem file đính kèm"
      centered
      hide-footer
    >
      <button-all-header
        :contentBtnAdd="'Thêm'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDowload="false"
        :hideDelete="false"
        :hideAdd="false"
        class="custtom-header"
        @search="search($event)"
      />
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataDownloadFile || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: false,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- tên -->
          <span v-if="props.column.field == 'name'">
            {{ props.row.name }}
          </span>

          <!-- Chức năng -->
          <span v-if="props.column.field == 'action'">
            <span>
              <feather-icon
                icon="DownloadIcon"
                size="18"
                @click="downloadFile(props.row.id, props.row.name)"
              />
            </span>
          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecordDownloadFile"
        :currentPage="queryParams.pageNumber"
        @pageClick="handlePageClick"
      />

    </b-modal>
  </div>
</template>
<script>
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
  },
  props: {
    id: {
      type: String,
      default: 'confirm-modal',
    },
    isNoClose: {
      type: Boolean,
      default: false,
    },
    idEdit: {
      type: String,
      default: '',
    },
    dataDownloadFile: {
      type: Array,
      default: _ => [],
    },
    totalRecordDownloadFile: {
      type: Number,
      default: 0,
    },
    queryParams: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      showBtnMultiDelete: false,
      totalItems: 20,
      currentPage: 10,
      columns: [
        {
          label: 'TÊN FILE',
          field: 'name',
        },
        {
          label: 'Chức năng',
          field: 'action',
          width: '175px',
        },
      ],
      confirmModalId: 'comfirm-modal-delete',
      modalContent: '',
      arrayExcel: null,
    }
  },
  methods: {
    // Tải file có trong giấy phép lao dộng
    downloadFile(id, val) {
      axiosApiInstance({
        url: '/WorkerLicense/download-file-in-worker-license', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { id },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', val) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // tìm kiếm tên file trong danh sách
    search(val) {
      this.$emit('searchFile', val)
    },

    // Phân trang danh sách file
    handlePageClick(pageNumber, pageSize) {
      this.$emit('handlePageClickPage', pageNumber, pageSize)
    },
  },
}
</script>

<style lang="scss">

</style>
