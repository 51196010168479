<template>
  <b-modal
    :id="id"
    size="md"
    title="Cấp lại cấp phép"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleRenewLicense"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group
        class="mt-1"
        label-for="classfication"
      >
        <label for="user">Số giấy cấp phép cấp lại <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customCode"
        >
          <b-form-input
            id="InputHelp"
            v-model="dataRenew.numberLicense"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        class="mt-1"
        label-for="classfication"
      >
        <label for="user">Ngày bắt đầu <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="{required: `Ngày bắt đầu là bắt buộc`}"
        >
          <date-time-picker
            v-model="dataRenew.dateStart"
            :disabledInput="true"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        class="mt-1"
        label-for="classfication"
      >
        <label for="user">Ngày kết thúc <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="{required: `Ngày kết thúc là bắt buộc`}"
        >
          <date-time-picker
            v-model="dataRenew.dateEnd"
            :disabledInput="true"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataRenew: {
        numberLicense: '',
        dateStart: '',
        dateEnd: '',
      },
      customCode: {
        required: 'Số giấy cấp phép cấp lại là bắt buộc',
      },
    }
  },
  methods: {
    handleRenewLicense(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleRenewLicense', this.dataRenew)
        }
      })
    },
    resetState() {
      this.dataRenew = {
        numberLicense: '',
      }
    },
  },
}
</script>
